import React, { useContext } from 'react';
import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';
import ServicesAreaItem from "./ServicesAreaItem";

const ServicesArea = () => {
  const { language } = useContext(LanguageContext);

  const services_area_list = [
    {
      title: LANGUAGE_CONSTANTS[language].AI_POWERED_DESIGN_TITLE,
      desc: LANGUAGE_CONSTANTS[language].AI_POWERED_DESIGN_DESC,
      delay_time: 2,
    },
    {
      title: LANGUAGE_CONSTANTS[language].INTERACTIVE_FUN_TITLE,
      desc: LANGUAGE_CONSTANTS[language].INTERACTIVE_FUN_DESC,
      delay_time: 3,
    },
    {
      title: LANGUAGE_CONSTANTS[language].PERSONALIZED_ARTWORK_TITLE,
      desc: LANGUAGE_CONSTANTS[language].PERSONALIZED_ARTWORK_DESC,
      delay_time: 4,
    }
  ];

  return (
    <section className="services-area pt-35 pb-95">
      <div className="container">
        <div className="row justify-content-center">
          {services_area_list.map((item, index) => (
            <div key={index} className="col-md-4 col-sm-10">
              <ServicesAreaItem index={index} item={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesArea;
