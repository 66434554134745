import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';
import { CHATBOT_LINK } from '../../constants';

const PRE_FILLED_TEXT = "?text=Halo%20saya%20ingin%20order!";

const NewsLetterAreaTwo = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section id="chatnow" className="newsletter-area-two pt-120">
      <div className="container">
        <div className="newsletter-wrap">

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10">
              <div className="section-title title-style-two white-title text-center mb-30">
                <span className="sub-title">{LANGUAGE_CONSTANTS[language].START_NOW_TITLE}</span>
                <h2 className="title">{LANGUAGE_CONSTANTS[language].START_NOW_SUB_TITLE}</h2>
                <p className="mt-25 text-light">{LANGUAGE_CONSTANTS[language].START_NOW_DESCRIPTION}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-6 wow slideInLeft mt-3">
                <Link to={CHATBOT_LINK.WhatsApp + PRE_FILLED_TEXT} target="_blank" rel="noreferrer" className="btn btn-outline-secondary w-100" style={{ background: "#605e7a" }}>
                  <img src="/img/brand/brand_img_whatsapp.png" alt="chat whatsapp" style={{ width: "150px", height: "auto" }} />
                </Link>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 wow slideInRight mt-3">
                <Link to={CHATBOT_LINK.Telegram + PRE_FILLED_TEXT} target="_blank" rel="noreferrer" className="btn btn-outline-secondary w-100" style={{ background: "#605e7a" }}>
                  <img src="/img/brand/brand_img_telegram.png" alt="chat telegram" style={{ width: "150px", height: "auto" }} />
                </Link>
              </div>
            </div>
          </div>

          <div className="newsletter-shape-wrap-two">
            <img src="/img/images/h3_chatbot.png" alt="" />
            <img src="/img/images/h3_newsletter_shape02.png" alt="" />
            <img src="/img/images/h3_newsletter_shape03.png" alt="" />
            <img src="/img/images/h3_newsletter_shape04.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsLetterAreaTwo;
