import { useEffect, useContext } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import WOW from "wow.js";
import CookieConsent from "react-cookie-consent";
import AnimatedCursor from "react-animated-cursor";
import PixelBrick from "./pages/Home/PixelBrick";
// import CreativeAgency from "./pages/Home/CreativeAgency";
// import PersonalPortfolio from "./pages/Home/PersonalPortfolio";
// import DigitalAgency from "./pages/Home/DigitalAgency";
// import AboutUs from "./pages/About/AboutUs";
// import AboutMe from "./pages/About/AboutMe";
// import TeamPage from "./pages/Team/TeamPage";
// import TeamPageDetails from "./pages/Team/TeamPageDetails";
// import PortfolioDetailsPage from "./pages/PortfolioDetailsPage";
// import ServicesDetailsPage from "./pages/ServicesDetailsPage";
// import ContactPage from "./pages/ContactPage";
// import BlogPage from "./pages/Blog/BlogPage";
// import BlogDetailsPage from "./pages/Blog/BlogDetailsPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import LanguageContext from './contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from './constants/language';

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
    const { language } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
        <CookieConsent
            location="bottom"
            buttonText={LANGUAGE_CONSTANTS[language].COOKIE_CONSENT_BUTTON}
            cookieName="PixelBrickCookieConsent"
            style={{ background: "#1f1d28" }}
            buttonStyle={{ background: "#f89e52", color: "#ffffff", fontSize: "13px" }}
            expires={150}
        >
            {LANGUAGE_CONSTANTS[language].COOKIE_CONSENT}
        </CookieConsent>
      <AnimatedCursor
        innerSize={8}
        outerSize={32}
        color="248, 158, 82"
        outerAlpha={0.15}
        innerScale={0}
        outerScale={0}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />

      <Routes>
          <Route path="/" element={<PixelBrick />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/terms-conditions" element={<TermsPage />} />

        {/*<Route path="/home-one" element={<CreativeAgency />} />*/}
        {/*<Route path="/home-two" element={<PersonalPortfolio />} />*/}
        {/*<Route path="/home-three" element={<DigitalAgency />} />*/}
        {/*<Route path="/about-us" element={<AboutUs />} />*/}
        {/*<Route path="/about-me" element={<AboutMe />} />*/}
        {/*<Route path="/team" element={<TeamPage />} />*/}
        {/*<Route path="/team-details" element={<TeamPageDetails />} />*/}
        {/*<Route path="/project-details" element={<PortfolioDetailsPage />} />*/}
        {/*<Route path="/services-details" element={<ServicesDetailsPage />} />*/}
        {/*<Route path="/contact" element={<ContactPage />} />*/}
        {/*<Route path="/blog" element={<BlogPage />} />*/}
        {/*<Route path="/blog-details" element={<BlogDetailsPage />} />*/}
      </Routes>
    </>
  );
}

export default App;
