import React, { useContext } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import TestimonialAreaItem from "./TestimonialAreaItem";
import $ from "jquery";
import { doAnimations } from "../../lib/helpers";
import cn from "classnames";
import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from "../../constants/language";

const TestimonialArea = () => {
  const { language } = useContext(LanguageContext);
  const slick_settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: function (currentSlide, nextSlide) {
      var $animatingElements = $(
        '.single-slider[data-slick-index="' + nextSlide + '"]'
      ).find("[data-animation]");
      doAnimations($animatingElements);
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      title: "Muhammad",
      designation: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TOKOPEDIA_BUYER,
      desc: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TPD_BUYER_MUHAMMAD,
      src: 8,
    },
    {
      title: "Meta",
      designation: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TOKOPEDIA_BUYER,
      desc: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TPD_BUYER_META,
      src: 7,
    },
    {
      title: "Cahya",
      designation: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TOKOPEDIA_BUYER,
      desc: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TPD_BUYER_CAHYA,
      src: 6,
    },
    {
      title: "Beliga",
      designation: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TOKOPEDIA_BUYER,
      desc: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TPD_BUYER_BELIGA,
      src: 9,
    },
    {
      title: "Clarissa",
      designation: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TOKOPEDIA_BUYER,
      desc: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TPD_BUYER_CLARISSA,
      src: 7,
    },
    {
      title: "Ariyanto",
      designation: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TOKOPEDIA_BUYER,
      desc: LANGUAGE_CONSTANTS[language].TESTIMONIALS_TPD_BUYER_ARIYANTO,
      src: 8,
    }
  ];

  return (
    <section className="testimonial-area pt-110 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">{LANGUAGE_CONSTANTS[language].TESTIMONIALS_TITLE}</span>
              <h2 className="title">{LANGUAGE_CONSTANTS[language].TESTIMONIALS_SUB_TITLE}</h2>
              <p className="mt-3">
                {LANGUAGE_CONSTANTS[language].TESTIMONIALS_SUB_TITLE_2} <a href="https://www.tokopedia.com/pixelbrick" target="_blank" rel="noreferrer">{LANGUAGE_CONSTANTS[language].TESTIMONIALS_SUB_TITLE_2_PAGE}</a>.
              </p>
            </div>
          </div>
        </div>

        <div className="testimonial-item-wrap">
          <div className="row testimonial-active">
            <SlickSlider settings={slick_settings}>
              {slider_items.map((x, index) => (
                <div
                  key={index}
                  className={cn(
                    "col-lg-3",
                    index % 2 === 0 && "testimonial-item-wrap-item-even"
                  )}
                >
                  <TestimonialAreaItem item={x} />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialArea;
