import React, { useContext } from 'react';
import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CODE } from "../../constants";

const LanguageSwitcher = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);

    const handleLanguageChange = (event) => {
        toggleLanguage(event.target.value);
    };

    return (
        <div className="form-group">
            <select
                className="form-select form-select-lg mb-3 rounded-pill"
                aria-label=".form-select-lg example"
                value={ language }
                style={{ width: 'auto' }}
                onChange={handleLanguageChange}>
                <option value={LANGUAGE_CODE.EN}>🇬🇧 English</option>
                <option value={LANGUAGE_CODE.ID}>🇮🇩 Bahasa Indonesia</option>
            </select>
        </div>
    );
};

export default LanguageSwitcher;
