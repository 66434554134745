import React, { useContext } from 'react';
import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';

const Faq = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section id="faq" className="faq-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="faq-img">
              <img src="/img/images/faq_img.png" alt="" />
              <img src="/img/images/faq_img02.png" alt="" />
            </div>
            <div className="faq-content">
              <div className="section-title title-style-two mb-20">
                <h2 className="title">
                  {LANGUAGE_CONSTANTS[language].FAQ_TITLE}
                  <br />
                  {LANGUAGE_CONSTANTS[language].FAQ_SUB_TITLE_1}
                </h2>
              </div>
              <h3 className="title-two">{LANGUAGE_CONSTANTS[language].FAQ_SUB_TITLE_2}</h3>
              <p>
                {LANGUAGE_CONSTANTS[language].FAQ_DESCRIPTION}
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="accordion faq-wrap" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {LANGUAGE_CONSTANTS[language].FAQ_QNA_1_QUESTION}
                  </button>
                </h2>

                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p style={{ textTransform: "none" }}>
                      {LANGUAGE_CONSTANTS[language].FAQ_QNA_1_ANSWER}
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {LANGUAGE_CONSTANTS[language].FAQ_QNA_2_QUESTION}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p style={{ textTransform: "none" }}>
                      {LANGUAGE_CONSTANTS[language].FAQ_QNA_2_ANSWER}
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {LANGUAGE_CONSTANTS[language].FAQ_QNA_3_QUESTION}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p style={{ textTransform: "none" }}>
                      {LANGUAGE_CONSTANTS[language].FAQ_QNA_3_ANSWER}
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    {LANGUAGE_CONSTANTS[language].FAQ_QNA_4_QUESTION}
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p style={{ textTransform: "none" }}>
                      {LANGUAGE_CONSTANTS[language].FAQ_QNA_4_ANSWER}
                      <br/>
                      <a href="https://www.tokopedia.com/pixelbrick" target="_blank" rel="noreferrer">PixelBrick Studio Official Store</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-shape-wrap">
        <img src="/img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
};

export default Faq;
