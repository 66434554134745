import React, { useEffect, useContext } from 'react';
import $ from "jquery";
import { Link } from "react-router-dom";

import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';

const HeaderThree = () => {
    useEffect(() => {
        /*=============================================
        =     Menu sticky & Scroll to top      =
    =============================================*/
        $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();
            if (scroll < 245) {
                $("#sticky-header").removeClass("sticky-menu");
                $(".scroll-to-target").removeClass("open");
            } else {
                $("#sticky-header").addClass("sticky-menu");
                $(".scroll-to-target").addClass("open");
            }
        });

        //SubMenu Dropdown Toggle
        if ($(".menu-area li.menu-item-has-children ul").length) {
            $(".menu-area .navigation li.menu-item-has-children").append(
                '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
            );
        }

        //Mobile Nav Hide Show
        if ($(".mobile-menu").length) {
            var mobileMenuContent = $(".menu-area .main-menu").html();
            $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

            //Dropdown Button
            $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
                "click",
                function () {
                    $(this).toggleClass("open");
                    $(this).prev("ul").slideToggle(300);
                }
            );
            //Menu Toggle Btn
            $(".mobile-nav-toggler").on("click", function () {
                $("body").addClass("mobile-menu-visible");
            });

            //Menu Toggle Btn
            $(".menu-backdrop, .mobile-menu .close-btn, .howitworks-menu, .gallery-menu, .faq-menu, .chat-now-menu")
                .on("click", function () {
                    $("body").removeClass("mobile-menu-visible");
            });
        }
    }, []);

    const { language } = useContext(LanguageContext);

    return (
        <>
            <header>
                <div className="menu-area transparent-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler">
                                    <i className="fas fa-bars"></i>
                                </div>

                                <div className="menu-wrap">
                                    <nav className="menu-nav">
                                        <div className="logo">
                                            <Link to="/">
                                                <img src="/img/logo/logo.png" alt="Logo" />
                                            </Link>
                                        </div>

                                        <div className="navbar-wrap main-menu d-none d-lg-flex">
                                            <ul className="navigation">

                                                <li>
                                                    <a className="howitworks-menu" href="#howitworks" onClick={() => $('html, body').animate({scrollTop: $('#howitworks').offset().top}, 'fast')}>{LANGUAGE_CONSTANTS[language].HEADER_HOW_IT_WORKS}</a>
                                                </li>

                                                <li>
                                                    <a className="gallery-menu" href="#gallery" onClick={() => $('html, body').animate({scrollTop: $('#gallery').offset().top}, 'fast')}>{LANGUAGE_CONSTANTS[language].HEADER_GALLERY}</a>
                                                </li>

                                                <li>
                                                    <a className="faq-menu" href="#faq" onClick={() => $('html, body').animate({scrollTop: $('#faq').offset().top}, 'fast')}>{LANGUAGE_CONSTANTS[language].HEADER_FAQ}</a>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="header-action">
                                            <ul className="list-wrap">
                                                <li className="header-btn">
                                                    <a href="#chatnow" className="btn"
                                                       onClick={() => $('html, body').animate({scrollTop: $('#chatnow').offset().top}, 'fast')}>
                                                        {LANGUAGE_CONSTANTS[language].HEADER_CHAT_NOW} <span></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>

                                {/* <!-- Mobile Menu  --> */}
                                <div className="mobile-menu">
                                    <nav className="menu-box">
                                        <div className="close-btn">
                                            <i className="fas fa-times"></i>
                                        </div>
                                        <div className="nav-logo">
                                            <Link to="/">
                                                <img src="/img/logo/logo.png" alt="Logo" />
                                            </Link>
                                        </div>
                                        <div className="menu-outer">
                                            {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}

                                        </div>
                                        <ul className="navigation">
                                            <li>
                                                <a className="chat-now-menu" href="#chatnow"
                                                   onClick={() => $('html, body').animate({scrollTop: $('#chatnow').offset().top}, 'fast')}>
                                                    {LANGUAGE_CONSTANTS[language].HEADER_CHAT_NOW} <span></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="menu-backdrop"></div>
                                {/* <!-- End Mobile Menu --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default HeaderThree;
