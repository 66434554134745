import React from "react";
// import { Link } from "react-router-dom";
import cn from "classnames";

const InnerProjectAreaItem = ({ item }) => {
  return (
    <>
      <div className={cn("inner-project-item horizoital-item")}>
        <div className="inner-project-thumb">
          <img src={item.src1} alt="" />
        </div>

        {/*<div className="inner-project-content">*/}
        {/*  <h3 className="title">*/}
        {/*    {item.title}*/}
        {/*  </h3>*/}
        {/*  <p>{item.desc}</p>*/}
        {/*</div>*/}
      </div>

      <div className={cn("inner-project-item vertical-item")}>
        <div className="inner-project-thumb">
          <img src={item.src2} alt="" />
        </div>

        {/*<div className="inner-project-content">*/}
        {/*  <h3 className="title">*/}
        {/*    {item.title}*/}
        {/*  </h3>*/}
        {/*  <p>{item.desc}</p>*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default InnerProjectAreaItem;
