import React, { useContext } from 'react';
import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import 'react-before-after-slider-component/dist/build.css';

const FIRST_IMAGE = {
  imageUrl: "https://pixelbrick.s3.ap-southeast-1.amazonaws.com/images/64f014e1d7b32a23fc4ecfd0/f4d75cccltaunes8/OFASnRsD3l65e3d60e6289e1308e62fd0d25.jpg"
};

const SECOND_IMAGE = {
  imageUrl: "https://pixelbrick.s3.ap-southeast-1.amazonaws.com/images/64f014e1d7b32a23fc4ecfd0/f4d75cccltaunes8/Gfglmq5VgZ00FPB.jpg"
};

const delimiterIconStyles = {
  width: '60px',
  height: '60px',
  backgroundSize: '5px 5px',  // Reduce the size to create 'padding'
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',  // Center the smaller image
  borderRadius: 'none',
  backgroundImage: 'url(/img/icon/scroll_left_right.svg)'
}

const AboutArea = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section className="about-area pb-120">
      <div className="container custom-container">
        <div className="about-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-46 order-0 order-lg-2">
              <div className="about-img text-end wow flipInX">
                <ReactBeforeSliderComponent
                    firstImage={ FIRST_IMAGE }
                    secondImage={ SECOND_IMAGE }
                    delimiterIconStyles={ delimiterIconStyles }
                    defaultSliderAnimation={{
                      initialStyles: { left: '0%' },
                      finalStyles: { left: '100%' },
                      duration: 1000,
                      timingFunction: 'ease',
                    }}
                />
              </div>
            </div>
            <div className="col-54">
              <div className="about-content">
              <div className="section-title mb-25">
                <span className="sub-title">{LANGUAGE_CONSTANTS[language].OUR_CRAFT_TITLE}</span>
                <h2 className="title">
                  {LANGUAGE_CONSTANTS[language].OUR_CRAFT_SUB_TITLE}
                </h2>
              </div>
              <p>
                {LANGUAGE_CONSTANTS[language].OUR_CRAFT_DESCRIPTION}
              </p>
                {/*<ul className="list-wrap">*/}
                {/*  <li>*/}
                {/*    <div className="content">*/}
                {/*      <h4 className="title">{LANGUAGE_CONSTANTS[language].OUR_CRAFT_FIRST_TITLE}</h4>*/}
                {/*      <p>*/}
                {/*        {LANGUAGE_CONSTANTS[language].OUR_CRAFT_FIRST_DESCRIPTION}*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <div className="content">*/}
                {/*      <h4 className="title">{LANGUAGE_CONSTANTS[language].OUR_CRAFT_SECOND_TITLE}</h4>*/}
                {/*      <p>*/}
                {/*        {LANGUAGE_CONSTANTS[language].OUR_CRAFT_SECOND_DESCRIPTION}*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutArea;
