import { LANGUAGE_CODE } from './index';

const LANGUAGE_CONSTANTS = {
    [LANGUAGE_CODE.EN]: {
        BANNER_TITLE: "Create LEGO® Compatible Brick Mosaics from Your Photos!",
        START_BUTTON: "Start Now",
        LEGO_DISCLAIMER: "LEGO® is a trademark of the LEGO Group of companies which does not sponsor, authorize or endorse this site. PixelBrick Studio does not advertise or sell LEGO® products.",
        FOOTER_RIGHTS: "© 2024 PixelBrick Studio.",
        AI_POWERED_DESIGN_TITLE: "AI-Powered Design",
        AI_POWERED_DESIGN_DESC: "Transform your memories into custom brick mosaics with our AI smart design system, blending technology with personalized artistry.",
        INTERACTIVE_FUN_TITLE: "Interactive Fun",
        INTERACTIVE_FUN_DESC: "PixelBrick Studio isn't just about receiving art; it's about creating it. Engage in an immersive building experience that brings your pixel art to life.",
        PERSONALIZED_ARTWORK_TITLE: "Personalized Artwork",
        PERSONALIZED_ARTWORK_DESC: "Every set is unique to your story. Our custom instruction manual guides you to relive memories as you build, piece by piece.",
        OUR_CRAFT_TITLE: "See the Transformation",
        OUR_CRAFT_SUB_TITLE: "Slide to Discover Before and After",
        OUR_CRAFT_DESCRIPTION: "Experience the magic of PixelBrick with our interactive slider. Drag left and right to see a stunning before-and-after comparison of a photo turned into a personalized LEGO-inspired mosaic.",
        VIDEO_TITLE: "Transform Any Photo into LEGO-Inspired Art",
        VIDEO_SUB_TITLE: "Build Your Moments",
        VIDEO_DESCRIPTION: "PixelBrick Studio offers a seamless journey from photo to mosaic, using advanced technology to craft LEGO-compatible brick artwork that captures the spirit of your favorite images. Engage with your creativity as never before and turn everyday moments into lasting memories. Experience the joy of bringing your photos to life, one brick at a time.",
        VIDEO_NOT_SUPPORTED: "Your browser does not support the video tag.",
        OUR_CRAFT_FIRST_TITLE: "Custom Mosaic Generation",
        OUR_CRAFT_FIRST_DESCRIPTION: "Transform your photos into a unique brick mosaic blueprint with our innovative technology, tailoring each design to your personal narrative.",
        OUR_CRAFT_SECOND_TITLE: "Brick Assembly Guide",
        OUR_CRAFT_SECOND_DESCRIPTION: "Receive step-by-step instructions that make building your personalized mosaic a fun and engaging experience, perfect for crafters and builders of all ages.",
        HOW_IT_WORKS_TITLE: "How It Works?",
        HOW_IT_WORKS_TITLE_2: "Watch and learn",
        HOW_IT_WORKS_SUB_TITLE: "how we transform your photos into custom brick mosaics.",
        HOW_IT_WORKS_DESCRIPTION: "This step-by-step video will show you the ease of transforming your captured moments into a personalized brick-building experience.",
        HOW_IT_WORKS_TESTIMONIAL: "Our platform is designed for easy use, offering you a seamless journey from photo upload to the joy of assembling. Discover the simplicity of crafting a personalized art piece with PixelBrick Studio.",
        SHOWCASE_TITLE: "Our Pixel Showcase",
        SHOWCASE_SUB_TITLE: "Custom Creations, Personalized Experience",
        SHOWCASE_DESCRIPTION: "Discover the stunning array of custom brick mosaics we’ve created. Each piece is a testament to the blend of individual expression and our sophisticated AI design. Your memories, reimagined into playful, tangible art.",
        SHOWCASE_CTA_BUTTON: "Make It Yours",
        FAQ_TITLE: "Need Help?",
        FAQ_SUB_TITLE_1: "We've Got You Covered",
        FAQ_SUB_TITLE_2: "Essential Insights for Your PixelBrick Path",
        FAQ_DESCRIPTION: "Our FAQ section is packed with information to guide you through your brick mosaic journey. Still have questions? Contact us for real-time assistance.",
        FAQ_QNA_1_QUESTION: "Can I personally build the mosaic? Will there be instructions?",
        FAQ_QNA_1_ANSWER: "Yes, indeed! Each set arrives with user-friendly instructions that are easy to follow. They're so detailed and simple that even first-timers to brick building will have no trouble at all. Assembling the mosaic is an enjoyable activity, perfect for some quality time on your own or with family and friends.",
        FAQ_QNA_2_QUESTION: "What type of photo should I use for the mosaic?",
        FAQ_QNA_2_ANSWER: "To achieve a great result, a high-quality photo with good lighting is key. For example, a close-up photo with a maximum of two people",
        FAQ_QNA_3_QUESTION: "Are these using official LEGO branded bricks?",
        FAQ_QNA_3_ANSWER: "We use bricks that are fully compatible with LEGO for a seamless building experience, although they are not LEGO-branded.",
        FAQ_QNA_4_QUESTION: "How can I pay for my PixelBrick mosaic?",
        FAQ_QNA_4_ANSWER: "We've made purchasing easy through Tokopedia, which supports various payment methods. Check out our official Tokopedia page for prices and more:",
        HEADER_HOW_IT_WORKS: "How it Works?",
        HEADER_GALLERY: "Gallery",
        HEADER_FAQ: "FAQ",
        HEADER_CHAT_NOW: "Chat Now (24/7)",
        FOOTER_LANGUAGE: "Language",
        FOOTER_PAGES: "Pages",
        FOOTER_WHATSAPP: "WhatsApp",
        FOOTER_TELEGRAM: "Telegram",
        FOOTER_TOKOPEDIA: "Tokopedia",
        FOOTER_PRIVACY: "Privacy Policy",
        FOOTER_TERMS: "Terms & Conditions",
        SPECIFICATION_TITLE: "Specifications",
        SPECIFICATION_SUB_TITLE: "Your Personalized Mosaic Pricing",
        SPECIFICATION_BASIC: "Basic",
        SPECIFICATION_KEY_1: "Dimensions",
        SPECIFICATION_VALUE_1: "40cm x 40cm",
        SPECIFICATION_KEY_2: "Resolution",
        SPECIFICATION_VALUE_2: "50x50 dots",
        SPECIFICATION_KEY_3: "Brick Count",
        SPECIFICATION_VALUE_3: "2500pcs",
        SPECIFICATION_KEY_4: "Brick Shape",
        SPECIFICATION_VALUE_4: "Round (Flat Tile 1x1)",
        SPECIFICATION_KEY_5: "Color Scheme",
        SPECIFICATION_VALUE_5: "Classic Monochrome",
        SPECIFICATION_KEY_6: "Price",
        SPECIFICATION_VALUE_6: "Rp 800.000",
        SPECIFICATION_VALUE_6_2: "Rp 699.000",
        START_NOW_TITLE: "Smart Chat, Instant Start",
        START_NOW_SUB_TITLE: "Our AI Chatbot is Ready to Guide Your Creative Experience",
        START_NOW_DESCRIPTION: "Chat now and let our AI chatbot guide you. Our smart AI chatbot is available 24/7 to launch your PixelBrick experience. It’s quick, easy, and ready whenever you are.",
        SUB_TITLE_COMPARISON_TABLE: "Comparison Table",
        TITLE_COMPARISON_TABLE: "A Clear Choice Over Other Brands",
        LINK_TITLE_COMPARISON_TABLE_1: "For the specifications of the",
        LINK_TITLE_COMPARISON_TABLE_2: "you can refer to",
        LINK_TEXT_COMPARISON_TABLE: "this link",
        COMPARISON_DIMENSIONS_TITLE: "Dimensions",
        COMPARISON_RESOLUTION_TITLE: "Resolution",
        COMPARISON_BRICK_COUNT_TITLE: "Brick Count",
        COMPARISON_BRICK_SHAPE_TITLE: "Brick Shape",
        COMPARISON_COLOR_VARIANTS_TITLE: "Color Variants",
        COMPARISON_PRICE_TITLE: "Price",
        COMPARISON_AVAILABILITY_TITLE: "Availability",
        COMPARISON_DIMENSIONS_VALUE_A: "40cm x 40cm",
        COMPARISON_RESOLUTION_VALUE_A: "50x50 dots",
        COMPARISON_BRICK_COUNT_VALUE_A: "2500 pieces",
        COMPARISON_BRICK_SHAPE_VALUE_A: "Round 1x1",
        COMPARISON_COLOR_VARIANTS_VALUE_A: "8 grayscale variants + 11 background colors",
        COMPARISON_PRICE_VALUE_A: "Rp 699,000",
        COMPARISON_AVAILABILITY_VALUE_A: "Available in 🇮🇩 Indonesia",
        COMPARISON_DIMENSIONS_VALUE_B: "38cm x 38cm",
        COMPARISON_RESOLUTION_VALUE_B: "48x48 dots",
        COMPARISON_BRICK_COUNT_VALUE_B: "2304 pieces",
        COMPARISON_BRICK_SHAPE_VALUE_B: "Rectangle 1x1",
        COMPARISON_COLOR_VARIANTS_VALUE_B: "4 grayscale variants + 1 yellow",
        COMPARISON_PRICE_VALUE_B: "US$79.99",
        COMPARISON_AVAILABILITY_VALUE_B: "Not available in Indonesia",
        COOKIE_CONSENT: "This website uses cookies to enhance the user experience.",
        COOKIE_CONSENT_BUTTON: "I understand",
        TESTIMONIALS_TITLE: "Our Rating 5/5 Stars",
        TESTIMONIALS_SUB_TITLE: "Discover why our customers love PixelBrick Studio",
        TESTIMONIALS_SUB_TITLE_2: "Visit our",
        TESTIMONIALS_SUB_TITLE_2_PAGE: "Tokopedia page",
        TESTIMONIALS_TOKOPEDIA_BUYER: "Tokopedia Buyer",
        TESTIMONIALS_TPD_BUYER_META: "Good, suitable for gifts and decorations. Overall, very good. Nice.",
        TESTIMONIALS_TPD_BUYER_MUHAMMAD: "Item as described, great!",
        TESTIMONIALS_TPD_BUYER_BELIGA: "Very good for home decoration. Can also custom design first on the website. The actual pixel colors and design time are equally accurate.",
        TESTIMONIALS_TPD_BUYER_CAHYA: "Fast delivery & matches the description.",
        TESTIMONIALS_TPD_BUYER_ARIYANTO: "Second purchase. Overall no complaints. Will order again for other family members.",
        TESTIMONIALS_TPD_BUYER_CLARISSA: "The product is cute, delivery is fast. Thanks, seller."
    },
    [LANGUAGE_CODE.ID]: {
        BANNER_TITLE: "Buat Brick Mozaik yang Kompatibel dengan LEGO® dari Foto Anda!",
        START_BUTTON: "Mulai Sekarang",
        LEGO_DISCLAIMER: "LEGO® adalah merek dagang dari Grup LEGO yang tidak mensponsori, mengizinkan, atau mendukung situs ini. PixelBrick Studio tidak mengiklankan atau menjual produk LEGO®.",
        FOOTER_RIGHTS: "© 2024 PixelBrick Studio.",
        AI_POWERED_DESIGN_TITLE: "Desain Berbasis AI",
        AI_POWERED_DESIGN_DESC: "Ubah kenangan Anda menjadi brick mozaik kustom dengan sistem desain cerdas AI kami, menggabungkan teknologi dengan keartisan personal.",
        INTERACTIVE_FUN_TITLE: "Kesenangan Interaktif",
        INTERACTIVE_FUN_DESC: "PixelBrick Studio bukan hanya tentang menerima seni; ini tentang menciptakannya. Terlibat dalam pengalaman membangun yang imersif yang membawa seni piksel Anda menjadi hidup.",
        PERSONALIZED_ARTWORK_TITLE: "Karya Seni Personalisasi",
        PERSONALIZED_ARTWORK_DESC: "Setiap set unik untuk cerita Anda. Manual instruksi kustom kami memandu Anda untuk menghidupkan kembali kenangan saat Anda membangun, satu per satu.",
        OUR_CRAFT_TITLE: "Lihat Transformasinya",
        OUR_CRAFT_SUB_TITLE: "Geser untuk Melihat Sebelum dan Sesudah",
        OUR_CRAFT_DESCRIPTION: "Rasakan keajaiban PixelBrick dengan slider interaktif kami. Geser ke kiri dan kanan untuk melihat perbandingan sebelum dan sesudah yang menakjubkan dari foto yang diubah menjadi mozaik yang terinspirasi oleh LEGO.",
        VIDEO_TITLE: "Ubah Foto Apapun menjadi Seni yang Terinspirasi LEGO",
        VIDEO_SUB_TITLE: "Buat Momen Anda",
        VIDEO_DESCRIPTION: "PixelBrick Studio menawarkan perjalanan mulus dari foto ke mozaik, menggunakan teknologi canggih untuk menciptakan karya seni batu bata yang kompatibel dengan LEGO yang menangkap semangat gambar favorit Anda. Terlibat dengan kreativitas Anda seperti belum pernah sebelumnya dan ubah momen sehari-hari menjadi kenangan abadi. Rasakan kegembiraan menghidupkan foto Anda, satu brick pada satu waktu.",
        VIDEO_NOT_SUPPORTED: "Browser Anda tidak mendukung tag video.",
        OUR_CRAFT_FIRST_TITLE: "Pembuatan Mozaik Kustom",
        OUR_CRAFT_FIRST_DESCRIPTION: "Ubah foto Anda menjadi brick mozaik yang unik dengan teknologi inovatif kami, menyesuaikan setiap desain dengan narasi pribadi Anda.",
        OUR_CRAFT_SECOND_TITLE: "Panduan Perakitan",
        OUR_CRAFT_SECOND_DESCRIPTION: "Terima instruksi langkah demi langkah yang membuat membangun mozaik personalisasi Anda menjadi pengalaman yang menyenangkan dan menarik, sempurna untuk para pengrajin dan pembangun dari semua usia.",
        HOW_IT_WORKS_TITLE: "Bagaimana Cara Kerjanya?",
        HOW_IT_WORKS_TITLE_2: "Tonton dan pelajari",
        HOW_IT_WORKS_SUB_TITLE: "bagaimana kami mengubah foto Anda menjadi kustom brick mozaik.",
        HOW_IT_WORKS_DESCRIPTION: "Video langkah demi langkah ini akan menunjukkan kemudahan mengubah momen yang Anda abadikan menjadi pengalaman membangun brick mozaik yang personalisasi.",
        HOW_IT_WORKS_TESTIMONIAL: "Platform kami dirancang untuk penggunaan yang mudah, menawarkan Anda pengalaman mudah dari upload foto hingga merakitnya. Temukan kemudahan membuat karya seni personalisasi dengan PixelBrick Studio.",
        SHOWCASE_TITLE: "Pameran Pixel Kami",
        SHOWCASE_SUB_TITLE: "Kreasi Kustom, Pengalaman Personalisasi",
        SHOWCASE_DESCRIPTION: "Temukan rangkaian brick mozaik kustom yang menakjubkan yang telah kami ciptakan. Setiap karya merupakan bukti perpaduan ekspresi individu dan desain AI kami yang canggih. Kenangan Anda, dihidupkan kembali menjadi seni yang nyata dan menyenangkan.",
        SHOWCASE_CTA_BUTTON: "Jadikan Milik Anda",
        FAQ_TITLE: "Butuh Bantuan?",
        FAQ_SUB_TITLE_1: "Kami Siap Membantu",
        FAQ_SUB_TITLE_2: "Wawasan Penting untuk Perjalanan PixelBrick Anda",
        FAQ_DESCRIPTION: "Bagian FAQ kami penuh dengan informasi untuk memandu Anda melalui perjalanan brick mozaik Anda. Masih memiliki pertanyaan? Hubungi kami untuk bantuan secara real time.",
        FAQ_QNA_1_QUESTION: "Apakah saya bisa membangun mozaik tersebut secara pribadi? Apakah akan ada instruksinya?",
        FAQ_QNA_1_ANSWER: "Ya, tentu saja! Setiap set datang dengan instruksi yang mudah untuk diikuti. Instruksinya sangat rinci dan sederhana sehingga bahkan orang yang baru pertama kali merakit brick pun tidak akan mengalami kesulitan sama sekali. Merakit brick mozaik adalah aktivitas yang menyenangkan, sempurna untuk menghabiskan waktu berkualitas sendirian atau bersama keluarga dan teman-teman.",
        FAQ_QNA_2_QUESTION: "Jenis foto apa yang harus saya gunakan untuk mozaik?",
        FAQ_QNA_2_ANSWER: "Untuk mendapatkan hasil yang bagus, foto berkualitas tinggi dengan pencahayaan yang baik adalah kuncinya. Sebagai contoh, foto close-up dengan maksimal dua orang.",
        FAQ_QNA_3_QUESTION: "Apakah ini menggunakan brick resmi merek LEGO?",
        FAQ_QNA_3_ANSWER: "Kami menggunakan brick yang sepenuhnya kompatibel dengan LEGO untuk pengalaman membangun yang mudah, meskipun ini bukan bermerek LEGO.",
        FAQ_QNA_4_QUESTION: "Bagaimana saya bisa membayar mozaik PixelBrick saya?",
        FAQ_QNA_4_ANSWER: "Kami telah memudahkan pembelian melalui Tokopedia, yang mendukung berbagai metode pembayaran. Kunjungi halaman Tokopedia resmi kami untuk harga dan informasi lebih lanjut:",
        HEADER_HOW_IT_WORKS: "Bagaimana Cara Kerjanya?",
        HEADER_GALLERY: "Galeri",
        HEADER_FAQ: "FAQ",
        HEADER_CHAT_NOW: "Chat Sekarang (24/7)",
        FOOTER_LANGUAGE: "Bahasa",
        FOOTER_PAGES: "Halaman",
        FOOTER_WHATSAPP: "WhatsApp",
        FOOTER_TELEGRAM: "Telegram",
        FOOTER_TOKOPEDIA: "Tokopedia",
        FOOTER_PRIVACY: "Kebijakan Privasi",
        FOOTER_TERMS: "Syarat & Ketentuan",
        SPECIFICATION_TITLE: "Spesifikasi",
        SPECIFICATION_SUB_TITLE: "Harga Mozaik Personalisasi Anda",
        SPECIFICATION_BASIC: "Dasar",
        SPECIFICATION_KEY_1: "Dimensi",
        SPECIFICATION_VALUE_1: "40cm x 40cm",
        SPECIFICATION_KEY_2: "Resolusi",
        SPECIFICATION_VALUE_2: "50x50 dot",
        SPECIFICATION_KEY_3: "Jumlah Brick",
        SPECIFICATION_VALUE_3: "2500pcs",
        SPECIFICATION_KEY_4: "Bentuk Brick",
        SPECIFICATION_VALUE_4: "Round (Flat Tile 1x1)",
        SPECIFICATION_KEY_5: "Skema Warna",
        SPECIFICATION_VALUE_5: "Klasik Monokrom",
        SPECIFICATION_KEY_6: "Harga",
        SPECIFICATION_VALUE_6: "Rp 800.000",
        SPECIFICATION_VALUE_6_2: "Rp 699.000",
        START_NOW_TITLE: "Chat Cerdas, Mulai Segera",
        START_NOW_SUB_TITLE: "AI Chatbot Kami Siap Membimbing Pengalaman Kreatif Anda",
        START_NOW_DESCRIPTION: "Chat sekarang dan biarkan bot chat AI kami memandu Anda. Bot chat AI cerdas kami tersedia 24/7 untuk memulai pengalaman PixelBrick Anda. Ini cepat, mudah, dan siap kapan pun Anda siap.",
        SUB_TITLE_COMPARISON_TABLE: "Tabel Perbandingan",
        TITLE_COMPARISON_TABLE: "Pilihan Jelas Dibandingkan Merek Lain",
        LINK_TITLE_COMPARISON_TABLE_1: "Untuk spesifikasi",
        LINK_TITLE_COMPARISON_TABLE_2: "Anda dapat merujuk ke",
        LINK_TEXT_COMPARISON_TABLE: "tautan ini",
        COMPARISON_DIMENSIONS_TITLE: "Dimensi",
        COMPARISON_RESOLUTION_TITLE: "Resolusi",
        COMPARISON_BRICK_COUNT_TITLE: "Jumlah Brick",
        COMPARISON_BRICK_SHAPE_TITLE: "Bentuk Brick",
        COMPARISON_COLOR_VARIANTS_TITLE: "Varian Warna",
        COMPARISON_PRICE_TITLE: "Harga",
        COMPARISON_AVAILABILITY_TITLE: "Ketersediaan",
        COMPARISON_DIMENSIONS_VALUE_A: "40cm x 40cm",
        COMPARISON_RESOLUTION_VALUE_A: "50x50 dot",
        COMPARISON_BRICK_COUNT_VALUE_A: "2500 buah",
        COMPARISON_BRICK_SHAPE_VALUE_A: "Bulat 1x1",
        COMPARISON_COLOR_VARIANTS_VALUE_A: "8 varian skala abu-abu + 11 warna latar",
        COMPARISON_PRICE_VALUE_A: "Rp 699,000",
        COMPARISON_AVAILABILITY_VALUE_A: "Tersedia di 🇮🇩 Indonesia",
        COMPARISON_DIMENSIONS_VALUE_B: "38cm x 38cm",
        COMPARISON_RESOLUTION_VALUE_B: "48x48 dot",
        COMPARISON_BRICK_COUNT_VALUE_B: "2304 buah",
        COMPARISON_BRICK_SHAPE_VALUE_B: "Persegi Panjang 1x1",
        COMPARISON_COLOR_VARIANTS_VALUE_B: "4 varian skala abu-abu + 1 kuning",
        COMPARISON_PRICE_VALUE_B: "US$79.99",
        COMPARISON_AVAILABILITY_VALUE_B: "Tidak tersedia di Indonesia",
        COOKIE_CONSENT: "Situs web ini menggunakan cookie untuk meningkatkan pengalaman pengguna.",
        COOKIE_CONSENT_BUTTON: "Saya mengerti",
        TESTIMONIALS_TITLE: "Penilaian Kami: 5/5 Bintang",
        TESTIMONIALS_SUB_TITLE: "Temukan mengapa pelanggan kami menyukai PixelBrick Studio",
        TESTIMONIALS_SUB_TITLE_2: "Kunjungi halaman",
        TESTIMONIALS_SUB_TITLE_2_PAGE: "Tokopedia kami",
        TESTIMONIALS_TOKOPEDIA_BUYER: "Pembeli Tokopedia",
        TESTIMONIALS_TPD_BUYER_META: "Baguuss, cocok buat kado dan pajangan. Overall, ok bgt. Bagus.",
        TESTIMONIALS_TPD_BUYER_MUHAMMAD: "Barang sesuai, mantap!",
        TESTIMONIALS_TPD_BUYER_BELIGA: "Bagus banget buat dijadikan pajangan di rumah. Bisa custom design juga terlebih dahulu di web nya. Warna pixel aslinya dan waktu desainnya juga sama akuratnya.",
        TESTIMONIALS_TPD_BUYER_CAHYA: "Pengiriman cepet & sesuai deskripsi.",
        TESTIMONIALS_TPD_BUYER_ARIYANTO: "Pembelian yang kedua. Overall tidak ada complain. Akan pesan lagi untuk anggota keluarga yang lain.",
        TESTIMONIALS_TPD_BUYER_CLARISSA: "Produknya lucu, kirimnya cepet. Thx seller."
    },
};

export { LANGUAGE_CONSTANTS };
