import React, { useContext } from 'react';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Iframe from "react-iframe";

import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';

const TestimonialAreaFive = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section id="howitworks" className="testimonial-area-five pt-100 pb-120">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="testimonial-img wow bounceIn">
              <img src="/img/images/tutorial_img.jpg" alt="" />

              <Popup
                trigger={
                  <a
                    href="#howitworks"
                    className="play-btn popup-video wow heartbeat" data-wow-duration="1.5s"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-play"></i>
                  </a>
                }
                position=""
                modal={true}
              >
                <Iframe
                  url="https://www.youtube.com/embed/bjAfXWXHYu4"
                  width="100%"
                  height="350px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </Popup>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="testimonial-content">
              <div className="section-title title-style-two mb-50">
                <span className="sub-title">{LANGUAGE_CONSTANTS[language].HOW_IT_WORKS_TITLE}</span>
                <div className="content">
                  <h2 className="title">
                    <span>{LANGUAGE_CONSTANTS[language].HOW_IT_WORKS_TITLE_2}</span> {LANGUAGE_CONSTANTS[language].HOW_IT_WORKS_SUB_TITLE}
                  </h2>
                  <p className="mt-3">{LANGUAGE_CONSTANTS[language].HOW_IT_WORKS_DESCRIPTION}</p>
                </div>
              </div>

              {/*<div className="testimonial-item-five">*/}
              {/*  <div className="testimonial-info">*/}
              {/*    <div className="thumb">*/}
              {/*      <img src="/img/images/testimonial_avatar04.png" alt="" />*/}
              {/*    </div>*/}

              {/*    <div className="content">*/}
              {/*      <h2 className="title">Sarah</h2>*/}
              {/*      <p>PixelBrick Studio</p>*/}
              {/*    </div>*/}
              {/*  </div>*/}

              {/*  <div className="testimonial-content-five">*/}
              {/*    <p>*/}
              {/*      "{LANGUAGE_CONSTANTS[language].HOW_IT_WORKS_TESTIMONIAL}"*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialAreaFive;
