import React from "react";
import TermsBreadcrumbArea from "../components/BreadcrumbArea/TermsBreadcrumbArea";
import Layout from "../layouts/Layout";

const PrivacyPage = () => {
    return (
        <Layout header={3} footer={1}>
            <TermsBreadcrumbArea />
        </Layout>
    );
};

export default PrivacyPage;
