import React, { useContext } from 'react';

import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';
import ComparisonTable from '../../components/ComparisonTable';

const PricingAreaTabs = () => {
  const { language } = useContext(LanguageContext);
  return (
      <>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
                className="nav-link active"
                id="details-tab"
                data-bs-toggle="tab"
                data-bs-target="#details-tab-pane"
                type="button"
                role="tab"
                aria-controls="details-tab-pane"
                aria-selected="true"
            >
              {LANGUAGE_CONSTANTS[language].SPECIFICATION_BASIC}
            </button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
              className="tab-pane fade show active"
              id="details-tab-pane"
              role="tabpanel"
              aria-labelledby="details-tab"
              tabIndex="0"
          >
            <div className="developer-info-wrap">
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-wrap">
                    <li>
                      <div className="icon">
                        <i className="fa fa-ruler-combined"></i>
                      </div>
                      <div className="content">
                        <span>{LANGUAGE_CONSTANTS[language].SPECIFICATION_KEY_1}:</span>
                        <p>{LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_1}</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-th-large"></i>
                      </div>
                      <div className="content">
                        <span>{LANGUAGE_CONSTANTS[language].SPECIFICATION_KEY_2}:</span>
                        <p>{LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_2}</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-cubes"></i>
                      </div>
                      <div className="content">
                        <span>{LANGUAGE_CONSTANTS[language].SPECIFICATION_KEY_3}:</span>
                        <p>{LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_3}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-wrap">
                    <li>
                      <div className="icon">
                        <i className="fa fa-circle"></i>
                      </div>
                      <div className="content">
                        <span>{LANGUAGE_CONSTANTS[language].SPECIFICATION_KEY_4}:</span>
                        <p>{LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_4}</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-palette"></i>
                      </div>
                      <div className="content">
                        <span>{LANGUAGE_CONSTANTS[language].SPECIFICATION_KEY_5}:</span>
                        <p>{LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_5}</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-tag"></i>
                      </div>
                      <div className="content">
                        <span>{LANGUAGE_CONSTANTS[language].SPECIFICATION_KEY_6}:</span>
                        <p className="h5 mb-4">
                          <span className="text-dark text-decoration-line-through me-2">{LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_6}</span>
                          <span className="text-danger fs-4">{LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_6_2}</span>
                        </p>
                        {/*<p className="fw-bold">*/}
                        {/*  <span className="badge bg-danger" style={{ fontSize: "1.25rem" }}>*/}
                        {/*    {LANGUAGE_CONSTANTS[language].SPECIFICATION_VALUE_6}*/}
                        {/*  </span>*/}
                        {/*</p>*/}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

const SinglePricingArea = () => {
  const { language } = useContext(LanguageContext);
  return (
      <section className="developr-area pb-120">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-9 order-0 order-lg-2">
              <div className="developr-img">
                <img src="/img/images/developer_img.png" alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="developr-content">
                <div className="section-title title-style-two mb-50">
                  <span className="sub-title">{LANGUAGE_CONSTANTS[language].SPECIFICATION_TITLE}</span>
                  <h2 className="title">
                    {LANGUAGE_CONSTANTS[language].SPECIFICATION_SUB_TITLE}
                  </h2>
                </div>

                {/* tabs */}
                <PricingAreaTabs />
              </div>
            </div>

          </div>
          <div className="row align-items-center justify-content-center mt-100">
            <div className="col-xs-12 col-sm-10 col-md-8">
              <div className="section-title title-style-two mb-50">
                <span className="sub-title">{LANGUAGE_CONSTANTS[language].SUB_TITLE_COMPARISON_TABLE}</span>
                <h4 className="sub-title" style={{ color: 'black', fontWeight: 'bold' }}>
                  {LANGUAGE_CONSTANTS[language].TITLE_COMPARISON_TABLE}
                </h4>
              </div>
              <ComparisonTable />
              <p className="mt-5" style={{ fontWeight: 'lighter', fontSize: 'smaller', fontStyle: 'italic' }}>* {LANGUAGE_CONSTANTS[language].LINK_TITLE_COMPARISON_TABLE_1} <span style={{ fontWeight: "bold", fontStyle: "italic" }}>Mosaic Maker 40179</span>, {LANGUAGE_CONSTANTS[language].LINK_TITLE_COMPARISON_TABLE_2} <a
                  href="https://www.lego.com/en-us/product/mosaic-maker-40179" target="_blank" rel="noreferrer">{LANGUAGE_CONSTANTS[language].LINK_TEXT_COMPARISON_TABLE}</a>.</p>
            </div>
          </div>
        </div>
      </section>
  );
};

export default SinglePricingArea;
