import React from "react";
import PrivacyBreadcrumbArea from "../components/BreadcrumbArea/PrivacyBreadcrumbArea";
import Layout from "../layouts/Layout";

const PrivacyPage = () => {
  return (
    <Layout header={3} footer={1}>
      <PrivacyBreadcrumbArea />
    </Layout>
  );
};

export default PrivacyPage;
