import React, { useEffect, useState, useRef } from 'react';
import { LANGUAGE_CODE } from '../constants';

const LanguageContext = React.createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('userLanguagePreference') || LANGUAGE_CODE.EN);
    const initialLanguageRef = useRef(language); // Store the initial language in a ref

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlLanguage = urlParams.get('lang');

        // Use initialLanguageRef.current to compare against the initial language
        if (urlLanguage && (urlLanguage === LANGUAGE_CODE.EN || urlLanguage === LANGUAGE_CODE.ID) && urlLanguage !== initialLanguageRef.current) {
            setLanguage(urlLanguage);
            localStorage.setItem('userLanguagePreference', urlLanguage);
        }
        // Since initialLanguageRef is a ref, it doesn't trigger re-renders or require inclusion in the dependency array
    }, []); // Still runs only once on mount

    const toggleLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem('userLanguagePreference', lang);
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageContext;
