import React from "react";
import AboutArea from "../../components/About/AboutArea";
import BannerOne from "../../components/Banner/BannerOne";
import ProjectArea from "../../components/Project/ProjectArea";
import ServicesArea from "../../components/ServicesArea/ServicesArea";
import Layout from "../../layouts/Layout";
import TestimonialAreaFive from "../../components/Testimonial/TestimonialAreaFive";
import TestimonialArea from "../../components/Testimonial/TestimonialArea";
import Faq from "../../components/Faq/Faq";
import NewsLetterAreaTwo from "../../components/NewsLetter/NewsLetterAreaTwo";
import InnerProjectArea from "../../components/Project/InnerProjectArea";
import SinglePricingArea from "../../components/Pricing/SinglePricingArea";
import TeamArea from "../../components/Team/TeamArea";

const PixelBrick = () => {
  return (
    <Layout header={3} footer={1}>
        <BannerOne />
        <ServicesArea />
        <TeamArea />
        <AboutArea />
        <SinglePricingArea />
        <TestimonialAreaFive />
        <ProjectArea />
        <InnerProjectArea />
        <TestimonialArea />
        <NewsLetterAreaTwo />
        <Faq />
    </Layout>
  );
};

export default PixelBrick;
