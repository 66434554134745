import React, { useContext } from 'react';
import $ from "jquery";

import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';

const BannerOne = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section className="banner-area banner-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-img">
              <div className="banner-image-container wow zoomIn" data-wow-delay="1s">
                <img src="/img/banner/stacks/top.png" alt="" className="banner-layered-image top" />
                <img src="/img/banner/stacks/middle.png" alt="" className="banner-layered-image middle" />
                <img src="/img/banner/stacks/bottom.png" alt="" className="banner-layered-image bottom" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-content">
              <span className="sub-title wow fadeInUp" data-wow-delay=".2s">
                <strong>PixelBrick</strong> Studio
              </span>
              <h2 className="title wow fadeInUp" data-wow-delay=".4s">
                {LANGUAGE_CONSTANTS[language].BANNER_TITLE}
              </h2>
              <a
                  href="#chatnow"
                  className="btn wow fadeInUp"
                  data-wow-delay=".6s"
                  onClick={() => $('html, body').animate({scrollTop: $('#chatnow').offset().top}, 'fast')}
              >
                {LANGUAGE_CONSTANTS[language].START_BUTTON} <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-shape-wrap">
        <img src="/img/banner/banner_shape01.png" alt="" />
        <img
          src="/img/banner/banner_shape02.png"
          alt=""
          className="animationFramesOne"
        />
        <img
          src="/img/banner/banner_shape03.png"
          alt=""
          className="contactSwimmer"
        />
        <img src="/img/banner/banner_shape04.png" alt="" className="rotateme" />
        <img
          src="/img/banner/banner_shape05.png"
          alt=""
          className="animation1"
        />
        <img
          src="/img/banner/banner_shape06.png"
          alt=""
          className="ribbonRotate"
        />
        <img
          src="/img/banner/banner_shape07.png"
          alt=""
          className="float-bob-x"
        />
      </div>
    </section>
  );
};

export default BannerOne;
