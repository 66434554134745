import React, { useContext } from 'react';
import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';
import { CHATBOT_LINK } from '../../constants';
import { Link } from "react-router-dom";
import LanguageSwitcher from "../../components/LanguageSwitcher";

const FooterOne = () => {
  const { language } = useContext(LanguageContext);
  return (
    <footer>
      <div className="footer-area">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-xs-12">
                <div className="footer-widget">
                  <h4 className="fw-title">{LANGUAGE_CONSTANTS[language].FOOTER_LANGUAGE}</h4>
                  <div className="footer-about">
                    <LanguageSwitcher />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-xs-12">
                <div className="footer-widget">
                  <h4 className="fw-title">{LANGUAGE_CONSTANTS[language].FOOTER_PAGES}</h4>
                  <div className="fw-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to={CHATBOT_LINK.WhatsApp} target="_blank" rel="noreferrer">{LANGUAGE_CONSTANTS[language].FOOTER_WHATSAPP}</Link>
                      </li>
                      <li>
                        <Link to={CHATBOT_LINK.Telegram} target="_blank" rel="noreferrer">{LANGUAGE_CONSTANTS[language].FOOTER_TELEGRAM}</Link>
                      </li>
                      <li>
                        <Link to="https://www.tokopedia.com/pixelbrick" target="_blank" rel="noreferrer">{LANGUAGE_CONSTANTS[language].FOOTER_TOKOPEDIA}</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">{LANGUAGE_CONSTANTS[language].FOOTER_PRIVACY}</Link>
                      </li>
                      <li>
                        <Link to="/terms-conditions">{LANGUAGE_CONSTANTS[language].FOOTER_TERMS}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 col-xs-12">
                <div className="footer-widget">
                  <div className="logo">
                    <Link to="/">
                      <img src="/img/logo/w_logo.png" alt="" style={{ maxWidth: '25%' }} />
                    </Link>
                  </div>
                  <div className="footer-contact">
                    <span>{LANGUAGE_CONSTANTS[language].LEGO_DISCLAIMER}</span>
                  </div>
                  <div className="footer-social">
                    <ul className="list-wrap">
                      <li className="active">
                        <a href="mailto:admin@pixelbrick.co.id" target="_blank" rel="noreferrer">
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/pixelbrick.co.id/" target="_blank" rel="noreferrer">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="copyright-text text-start">
                  <p>{LANGUAGE_CONSTANTS[language].FOOTER_RIGHTS}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
