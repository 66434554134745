import React, { useMemo, useContext } from 'react';
import { useTable } from 'react-table';

import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';

const ComparisonTable = () => {
    const { language } = useContext(LanguageContext);
    // Data for the table
    const data = useMemo(
        () => [
            {
                feature: LANGUAGE_CONSTANTS[language].COMPARISON_DIMENSIONS_TITLE,
                pixelBrick: LANGUAGE_CONSTANTS[language].COMPARISON_DIMENSIONS_VALUE_A,
                lego: LANGUAGE_CONSTANTS[language].COMPARISON_DIMENSIONS_VALUE_B,
                highlight: false
            },
            {
                feature: LANGUAGE_CONSTANTS[language].COMPARISON_RESOLUTION_TITLE,
                pixelBrick: LANGUAGE_CONSTANTS[language].COMPARISON_RESOLUTION_VALUE_A,
                lego: LANGUAGE_CONSTANTS[language].COMPARISON_RESOLUTION_VALUE_B,
                highlight: false
            },
            {
                feature: LANGUAGE_CONSTANTS[language].COMPARISON_BRICK_COUNT_TITLE,
                pixelBrick: LANGUAGE_CONSTANTS[language].COMPARISON_BRICK_COUNT_VALUE_A,
                lego: LANGUAGE_CONSTANTS[language].COMPARISON_BRICK_COUNT_VALUE_B,
                highlight: false
            },
            {
                feature: LANGUAGE_CONSTANTS[language].COMPARISON_BRICK_SHAPE_TITLE,
                pixelBrick: LANGUAGE_CONSTANTS[language].COMPARISON_BRICK_SHAPE_VALUE_A,
                lego: LANGUAGE_CONSTANTS[language].COMPARISON_BRICK_SHAPE_VALUE_B,
                highlight: false
            },
            {
                feature: LANGUAGE_CONSTANTS[language].COMPARISON_COLOR_VARIANTS_TITLE,
                pixelBrick: LANGUAGE_CONSTANTS[language].COMPARISON_COLOR_VARIANTS_VALUE_A,
                lego: LANGUAGE_CONSTANTS[language].COMPARISON_COLOR_VARIANTS_VALUE_B,
                highlight: false
            },
            {
                feature: LANGUAGE_CONSTANTS[language].COMPARISON_PRICE_TITLE,
                pixelBrick: LANGUAGE_CONSTANTS[language].COMPARISON_PRICE_VALUE_A,
                lego: LANGUAGE_CONSTANTS[language].COMPARISON_PRICE_VALUE_B,
                highlight: false
            },
            {
                feature: LANGUAGE_CONSTANTS[language].COMPARISON_AVAILABILITY_TITLE,
                pixelBrick: LANGUAGE_CONSTANTS[language].COMPARISON_AVAILABILITY_VALUE_A,
                lego: LANGUAGE_CONSTANTS[language].COMPARISON_AVAILABILITY_VALUE_B,
                highlight: false
            }
        ],
        [language]
    );

    // Column configuration for the table
    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'feature' // accessor is the "key" in the data
            },
            {
                Header: 'PixelBrick',
                accessor: 'pixelBrick',
                Cell: ({ value, row }) => (
                    <div style={row.original.highlight ? { color: 'green', fontWeight: 'bold' } : {}}>
                        {value}
                    </div>
                )
            },
            {
                Header: 'Mosaic Maker 40179',
                accessor: 'lego'
            }
        ],
        []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    // Render the UI for your table
    return (
        <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} style={{ borderBottom: '1px solid black', padding: 10 }}>
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()} style={{ padding: 10, borderBottom: '1px solid black' }}>
                                    {cell.render('Cell')}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default ComparisonTable;
